export const test1 = {
    in: {
        opacity: 1
    },
    out: {
        opacity : 0
    },
};

export const transition = {
    duration:  1
};
